import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { GiCheckMark } from 'react-icons/gi';
import { Spinner } from '@/Spinner';
import { isBrowser } from '@/utils/browser';
import { useStoreSettingsApi } from '@/features/integrations/facebook/api';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
    user-select: none;
    font-size: 1.5rem;
    font-family: 'JetBrains Mono', serif;
  }
`;

export interface FacebookAuthToken {
  code: string;
  redirectURI: string;
  state: string;
}

export const FacebookOauthRedirect: FC = () => {
  const size = 200;
  const { persist, loading } = useStoreSettingsApi();

  useEffect(() => {
    if (isBrowser()) {
      window.location.search;
      const params = new URL(window.location.href).searchParams;
      const code = params.get(`code`);
      const { redirectURI, state } = JSON.parse(atob(params.get(`state`))) as {
        state: string;
        redirectURI: string;
      };
      persist({ code, state, redirectURI }).then((_) => {
        const ch = new BroadcastChannel(`meta_auth`);
        ch.postMessage(`done`);
        setTimeout((_) => {
          ch.close();
          window.close();
        }, 700);
      });
    }
  }, []);
  return (
    <Container>
      {loading ? (
        <Spinner size={size} />
      ) : (
        <>
          <GiCheckMark color="green" size={size} />
          <p>Thank You, You can close the tab now.</p>
        </>
      )}
    </Container>
  );
};
